.aui-root {
  .map-page {
    display: flex;
    flex-direction: row;
    &-menu {
      width: 42vw;
      .back-btn.space-between {
        justify-content: space-between;
        margin: 2vh 0;
      }
      .back-btn {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        margin-top: 2vh;
        .flex-box {
          display: flex;
          flex-direction: row;
          cursor: pointer;
          .aui-icon {
            display: flex;
            align-items: center;
          }
          p {
            margin: 0;
            align-self: center;
          }
          .arrow svg {
            width: 1rem;
            height: 1rem;
            color: var(--dark-contrast);
          }
        }
        .expand-container {
          cursor: pointer;
        }
        .expand-icon svg {
          width: 2rem;
          height: 2rem;
        }
        p {
          margin: 0.4rem 0 0 0;
          color: var(--dark-contrast);
        }
      }
    }
    &-content {
      display: flex;
      flex-direction: column;
      height: 90vh;
      &__buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
        margin-top: 1rem;
      }
      &__vectors {
        height: 80vh;
        overflow-y: auto;
        .vector-layer,
        .feature {
          display: flex;
          border-bottom: 1px solid #e5e7eb;
          cursor: pointer;
          min-height: 4rem;
          p {
            margin: 0.5rem 0;
          }
        }
        .vector-layer {
          justify-content: space-between;
          align-items: center;
          gap: 1rem;
        }
        .vector-layer.disabled {
          opacity: 0.5;
          cursor: default;
        }
        .feature {
          display: flex;
          flex-direction: column;

          .map-layer-row {
            display: flex;
            .map-layer-left-text {
              flex: 1;
            }
            .map-layer-right-text {
              flex: 3;
            }
          }
        }
        .feature.selected {
          background-color: #eef2f6;
        }
      }
      &__vectors.message-drawmode {
        height: 70vh;
        overflow-y: auto;
      }
    }
    &-map {
      width: 45%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
    }
    &-map.fullscreen {
      width: 90vw;
    }
  }
}

.map-page.small {
  width: 5%;
  margin-left: -4.5vw;
}

.collapse-container {
  z-index: 100;
}

.offline-text {
  position: absolute;
  top: 0.1vh;
  left: 18vw;
}
