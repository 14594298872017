.aui-root {
  .confirmation-modal-content {
    &__text {
      p {
        margin-top: 0;
      }
    }
    &__buttons {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      .btn,
      .btn-light {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        width: 30%;
      }
    }
  }
}
