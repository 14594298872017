.map-view {
  .map-view--overlay {
    position: absolute;
    left: auto;
    top: auto;
    right: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    .btn.map {
      margin-top: 0;
      margin-left: 0;
    }
    .btn.map:hover,
    .btn.position:hover {
      background-color: var(--primary);
    }
    .btn.map.icon.zoom-in {
      margin-bottom: 0.5rem;
    }
    .btn.position,
    .btn.layers {
      position: absolute;
      top: -3rem;
      right: 0.5rem;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .btn.layers {
      top: 0;
      right: 9.5rem;
    }
    .btn.layers:hover {
      background-color: var(--primary);
    }
    .btn.layers.active {
      background-color: var(--primary-contrast);
    }
    .btn.position.active {
      background-color: white;
      svg {
        color: var(--primary);
      }
    }
  }
}
