.aui-root {
  .plain-text-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    .plain-text-button {
      color: var(--primary);
      text-decoration: underline;
      transition: 0.15s color;
      cursor: pointer;
      display: flex;
      gap: 0.5rem;

      &:hover {
        color: var(--defaultTextColor);
      }

      &--disabled {
        pointer-events: none;
        color: var(--disabled);
      }
    }
  }
}
