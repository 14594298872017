.aui-root {
  .map-options-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 2vh;
    right: 2.5vw;
    width: 40%;
    gap: 1rem;
    .map-options {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      background-color: var(--white);
      border-radius: 0.5rem;
      box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);
      z-index: 2;
      &-title {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid #d0d5dd;
        padding: 0.8rem 0.8rem 0 0.8rem;
        background-color: #f9fafb;
        border-radius: 0.5rem 0.5rem 0 0;
        height: 5rem;
        &-icon {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          min-width: 73px;
          p {
            margin: 0.5rem 0;
          }
        }
        .title {
          margin-left: 1.5rem;
        }
        .title.map {
          margin: 0.5rem 0.5rem 1rem 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      &-buttons {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        padding: 0.8rem;
        .btn-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          min-width: 73px;
          p {
            margin: 0.8rem 0 0 0;
          }
          .option-btn {
            background-color: #eef2f6;
            border: none;
            border-radius: 2rem;
            width: 3rem;
            height: 3rem;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            margin: 0;

            .aui-icon svg {
              color: var(--gray-600, #4b5565);
            }
          }
          .selected {
            background-color: var(--primary);
            .aui-icon svg {
              color: var(--white);
            }
          }
        }
        .btn-container.disabled {
          opacity: 0.5;
          .option-btn {
            cursor: default;
          }
        }
      }
    }
    .map-options.disabled {
      background-color: transparentize($color: #ffffff, $amount: 0.2);
      border: 1px solid var(--gray-200, #e2e8f0);
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    }
    .map-options.hidden {
      visibility: hidden;
      pointer-events: none;
      z-index: -1;
    }
    .option-popup.hidden {
      visibility: hidden;
      pointer-events: none;
      z-index: -1;
    }
    .option-popup {
      z-index: 2;
      width: 100%;
    }
    .map-options.mobile {
      border-radius: 0.5rem 0.5rem 0 0;
      border: 1px solid var(--gray-600, #4b5565);
      border-bottom: none;
      box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);
    }
  }
  .map-options-container.mobile {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
    gap: 0;
    .option-popup {
      .mobile {
        border-radius: 0 0 0.5rem 0.5rem;
      }
    }
  }
}
