.aui-root {
  .driving-modal {
    &-body {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      p {
        margin: 0;
      }
      .details-dropdown-container {
        .dropdown-input {
          width: 100%;
        }
      }
    }

    &-sum {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      p {
        margin: 0;
      }
      &-title {
        p {
          margin-top: 1rem;
        }
      }
      &-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        p.rest-ppp {
          flex: 1.5;
        }
        p.bio {
          flex: 1;
        }
        p.total {
          flex: 1.1;
        }
      }
      &-item.mobile {
        p {
          font-size: small;
        }
      }
    }

    &-request-failed {
      color: #f04438;
    }

    &-footer {
      display: flex;
      justify-content: space-between;
      margin-top: 2rem;
    }
    .weight-warning {
      color: #dc6803;

      margin-bottom: 0;
    }
  }
}
