body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.aui-root {
  --default-font: "Inter", sans-serif;
  background-color: var(--inputBackground);
  .btn-light {
    border: 1px solid #d0d5dd;
    background-color: white;
    border-radius: 0.6rem;
    padding: 0.5rem 1rem;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    color: var(--dark-contrast);
  }
  .btn-light:hover {
    background-color: #f9fafb;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
  }
  .btn-light:active {
    background-color: #f0f2f5;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    border-radius: 0.6rem;
  }
  .btn {
    border: none;
    background-color: var(--primary);
  }
  .btn:hover {
    background-color: var(--primary-contrast);
  }
  .btn:active {
    background-color: var(--primary);
  }
  .expanded-options-menu {
    right: 2.5vw;
  }
  .collapsed-options-menu {
    right: 22.5vw;
  }
}

::-webkit-scrollbar {
  width: thin;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background: #d0d5dd;
  border-radius: 0.5rem;
}
