.aui-root {
  .object-properties {
    overflow-y: auto;
    max-height: 68vh;
    background-color: white;
    border-radius: 0.5rem;
    border: 1px solid var(--gray-600, #4b5565);
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);
    padding: 1rem;
    &-header {
      display: flex;
      justify-content: space-between;
      &-title {
        margin-top: 0;
      }
    }
    &-column {
      display: flex;
      flex-direction: column;
    }
    &-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      .object-key {
        margin-bottom: 0.3rem;
        flex: 1.5;
      }
      .object-value {
        margin-bottom: 0.2rem;
        flex: 2;
      }
    }
  }
}
