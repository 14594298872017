.aui-root {
  .text-button {
    width: fit-content;
    padding: 1rem 2rem;
    gap: 1rem;
    p {
      color: var(--white);
      margin: 0 !important;
      white-space: nowrap;
    }
  }
  .text-button.inverted {
    background-color: var(--white);
    border: 1px solid var(--primary);
    p {
      color: var(--primary);
    }
  }
  .text-button.inverted:hover {
    background-color: #eef2f6;
  }
}
