.aui-root {
  .settings-wrapper {
    width: 440px;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border-right: 1px solid var(--primary);
    padding-right: 20px;

    .settings-panel {
      .settings-header {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        align-items: center;
      }
      .settings-content {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: 1rem;
      }
    }

    .settings-footer {
      bottom: 2rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      .developed-by {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        img {
          width: 2rem;
          height: 2rem;
        }
        p {
          margin: 0;
          white-space: nowrap;
        }
      }
    }
  }
}
