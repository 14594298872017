.aui-root {
  .message {
    background-color: white;
    border-radius: 0.5rem;
    border: 1px solid var(--gray-600, #4b5565);
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);
    padding: 1rem;
    overflow-y: auto;
    max-height: 68vh;
    &-header {
      display: flex;
      justify-content: space-between;
      &-title {
        margin-top: 0;
      }
    }
    &-form {
      display: flex;
      flex-direction: column;

      &-draw-btn-container {
        display: flex;
        margin-top: 1rem;
        justify-content: space-between;
        gap: 1rem;
      }

      p {
        margin-bottom: 0.2rem;
      }

      .details-dropdown-container {
        width: 100%;

        .dropdown-input {
          width: 100%;
        }
      }
      &-column {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;

        textarea {
          resize: none;
          height: 6rem;
        }
      }

      .file-preview {
        margin-bottom: 10px;
      }

      .countdown-container {
        position: relative;
        height: 4px;
        width: 100%;
        background: "#ccc";
        .countdown-bar {
          position: absolute;
          bottom: 0.5rem;
          height: 100%;
          width: 100%;
          background: var(--primary);
          animation: shrink 1s linear forwards;
        }

        @keyframes shrink {
          from {
            width: 100%;
          }
          to {
            width: 0%;
          }
        }
      }

      &-btn-container {
        display: flex;
        justify-content: space-between;
      }

      &-btn-container.saved {
        .btn {
          opacity: 1;
          filter: none;
          span {
            color: var(--primary);
          }
        }
        .btn:hover {
          background-color: white;
        }
      }

      &-detail-container {
        .separator {
          margin-top: 1rem;
          border-bottom: 1px solid #ccc;
        }
        .detail-btn-container {
          display: flex;
          justify-content: end;
          .add-detail-btn {
            margin-top: 0.5rem;
            padding: 0.5rem;
            width: fit-content;
            justify-content: center;
            align-items: center;
            p {
              margin: 0;
            }
          }
          .push-detail-btn {
            margin-top: 0.5rem;
            padding: 0.5rem;
            width: 5rem;
            justify-content: center;
            align-items: center;
            p {
              margin: 0;
            }
          }
        }
      }
      .details-title {
        margin-top: 0.5rem;
        margin-bottom: -0.2rem;
      }
      &-added-details-container {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin-top: 1.5rem;
        .detail-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: fit-content;
          padding: 0.2rem 1rem;
          border: 1px solid var(--primary);
          border-radius: 2rem;
          gap: 0.5rem;
          .remove-detail-btn {
            display: flex;
            gap: 0.5rem;
            padding: 0.2rem;
            background-color: white;
          }
        }
      }
    }
  }
  .message.hidden {
    visibility: hidden;
    pointer-events: none;
    z-index: -1;
  }
}
