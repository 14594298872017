.aui-root {
  .map-layer-list {
    position: absolute;
    bottom: 3.5rem;
    right: 4.5rem;
    display: flex;
    flex-direction: column;
    height: 30%;
    width: 20rem;
    z-index: 100;
    background-color: white;
    border-radius: 0.5rem;
    opacity: 0.8;

    .search-container {
      display: flex;
      align-items: center;
      padding: 0.5rem;
      border-top: 1px solid #dbdbdb;
      input {
        width: 100%;
      }
    }

    &--content {
      flex: 1; // Take up available space
      overflow-y: auto;
    }

    &--item {
      padding: 0.5rem;

      label {
        display: flex;
        align-items: center;
        cursor: pointer;
      }

      input[type="checkbox"] {
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
      }
    }
    &--item.offline {
      label {
        cursor: default;
        color: #dbdbdb;
      }
    }
    &--item.uncheck {
      border-bottom: 1px solid #dbdbdb;
    }

    &--layers {
      margin-top: 10px;
    }
  }
  .map-layer-list.tall {
    height: 35%;
  }
}
