.aui-root {
  .log {
    overflow-y: auto;
    max-height: 68vh;
    background-color: white;
    border: 1px solid var(--gray-600, #4b5565);
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);
    border-radius: 0.5rem;
    padding: 1rem;
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.5rem;
      &-buttons {
        display: flex;
        gap: 1rem;
        flex-direction: row;
        align-items: center;
        p {
          margin: 0;
        }
      }
    }
    .arrow-container {
      display: flex;
      align-items: center;
      width: fit-content;
      padding-bottom: 1rem;
      span {
        display: flex;
        align-items: center;
      }
      .aui-icon svg {
        width: 1rem;
        height: 1rem;
      }
      p {
        margin: 0;
      }
    }
    .arrow-container:hover {
      cursor: pointer;
    }
    &-title {
      margin-top: 0;
    }
    &-column {
      display: flex;
      flex-direction: column;
      p {
        margin: 0.5rem 0;
      }
      .matching {
        color: var(--primary-light);
      }
    }

    .attachments {
      width: 100%;
    }

    &-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid var(--light-contrast);
      .icon-row {
        display: flex;
        gap: 1rem;
        .aui-icon svg {
          width: 1.2rem;
          height: 1.2rem;
        }
      }
    }
    &-row:hover {
      background-color: var(--light-contrast);
      cursor: pointer;
    }
  }
}
