.aui-root {
  .log-details {
    &-row {
      display: flex;
      flex-direction: row;
    }
    &-column:first-child {
      flex: 2;
    }
    &-column:last-child {
      flex: 1;
    }
  }
}
