.aui-root {
  .order-info-modal {
    &-body {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      p {
        margin: 0;
      }
      &-pair {
        display: flex;
        flex-direction: column;
      }
    }
    &-footer {
      display: flex;
      justify-content: end;
      margin-top: 2rem;
      .btn {
        width: 40%;
        display: flex;
        justify-content: center;
      }
    }
  }
}
