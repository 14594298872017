.aui-root .login {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  &__form {
    .label {
      margin: 0;
      align-self: flex-start;
    }
    &__header {
      margin-bottom: 0;
    }
    &__welcome {
      margin-bottom: 1.2rem;
    }
    &__error {
      color: var(--danger);
      margin-bottom: 0;
    }
    &__error.hidden {
      display: none;
    }
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      button {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      input {
        width: 100%;
        margin-bottom: 2rem;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      a {
        align-self: flex-end;
      }
      .text-btn-container {
        align-self: flex-end;
        .forgot-password {
          position: relative;
          margin: 0;
          right: 0;
          &:hover {
            cursor: pointer;
            text-decoration: none;
          }
        }
      }
    }
  }
}
