.background-map-downloader {
  &--container {
    min-width: 300px;
  }

  &--header.text {
    display: block;
  }

  &--info.text {
    display: block;
    margin: 0, 1rem;
  }
}
