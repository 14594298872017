.aui-root {
  .register-object {
    background-color: white;
    border-radius: 0.5rem;
    border: 1px solid var(--gray-600, #4b5565);
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);
    padding: 1rem;
    overflow-y: auto;
    max-height: 68vh;
    &-header {
      display: flex;
      justify-content: space-between;
      &-title {
        margin-top: 0;
      }
    }
    &-form {
      display: flex;
      flex-direction: column;
      p {
        margin-bottom: 0.2rem;
      }
      pre {
        font-family: "Arial", sans-serif;
        margin-top: 0;
      }
      &-btn-container {
        .btn {
          display: flex;
          width: 100%;
          justify-content: center;
        }
        .countdown-container {
          position: relative;
          height: 4px;
          width: 100%;
          background: "#ccc";
          .countdown-bar {
            position: absolute;
            bottom: 0.5rem;
            height: 100%;
            width: 100%;
            background: var(--primary);
            animation: shrink 1s linear forwards;
          }

          @keyframes shrink {
            from {
              width: 100%;
            }
            to {
              width: 0%;
            }
          }
        }
      }
      &-btn-container.saved {
        .btn {
          opacity: 1;
          filter: none;
          span {
            color: var(--primary);
          }
        }
        .btn:hover {
          background-color: white;
        }
      }
      &-column {
        display: flex;
        flex-direction: column;
        .dropdown-input {
          width: 100%;
        }
      }
      textarea {
        resize: none;
        height: 6rem;
      }
    }
    &-status {
      display: flex;
      flex-direction: column;
      &-btns {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: 1.5rem;
        gap: 0.5rem;
      }
      &-btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        border: 1px solid #e3e8ef;
        cursor: pointer;
        width: 100%;
        height: 3rem;
        p {
          margin: 0;
        }
        .radioCheck {
          padding: 0.2rem;
          border-radius: 50%;
        }
        .radioCheck.Fullført {
          border: 1px solid #079455;
        }
        .radioCheck.Fullført.selected {
          background-color: #dcfae6;
        }
        .radioCheck.Ikke.fullført {
          border: 1px solid #f79009;
        }
        .radioCheck.Ikke.fullført.selected {
          background-color: #fef0c7;
        }
        .radioCheckDot {
          width: 0.4rem;
          height: 0.4rem;
          border-radius: 50%;
        }
        .radioCheckDot.Fullført.selected {
          background-color: #079455;
        }
        .radioCheckDot.Ikke.fullført.selected {
          background-color: #f79009;
        }
      }
      &-btn.Fullført.selected {
        background-color: #dcfae6;
        p {
          color: #079455;
        }
      }
      &-btn.Fullført {
        border: 1px solid #079455;
      }

      &-btn.Ikke.fullført.selected {
        background-color: #fffaeb;
        border: 1px solid #f79009;
        p {
          color: #f79009;
        }
      }
      &-btn.Ikke.fullført {
        border: 1px solid #f79009;
      }
    }
    &-warning {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
    }
  }
}
