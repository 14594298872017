.details-dropdown-container {
  display: flex;
  flex-direction: column;

  .dropdown-input {
    width: 11rem;
    max-height: 2.5rem;
    cursor: pointer;
  }
  .dropdown-input.fit-content {
    width: fit-content;
  }
}

.aui-root {
  input[type="text"] {
    line-height: 1.2rem;
  }
}
