.barcode-scanner-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  backdrop-filter: blur(5px); /* Blurred background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.barcode-scanner-content {
  background: white;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  width: 90%;
  width: 600px;
  height: 80%;
}

.result-text {
  position: absolute;
  bottom: 10px;
  left: 30px;
  background: transparent;
  border: none;
  font-size: 1rem;
  color: #f04438;
}
