.aui-root {
  .dropdown-container {
    margin-bottom: 1vh;
    .gap-1 {
      display: flex;
      gap: 1rem;
      justify-content: center;
      align-items: center;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    select {
      color: var(--dark-contrast);
      height: 2.5rem;
      cursor: pointer;
    }
    button {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      height: 2.5rem;
    }
  }
  .table-container {
    width: 100%;
    overflow-x: auto;
    border: 1px solid var(--light-contrast);
    border-radius: 1rem;

    .header-row {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid var(--light-contrast);
      font-weight: bold;
      padding: 8px 16px;
      background-color: #f9fafb;

      .header-cell {
        text-align: left;
        font-size: 0.9rem;
        font-weight: 400;
        color: var(--gray-600, #475467);
      }
      .header-cell.status {
        flex: 0.7;
      }
      .header-cell.progress {
        flex: 1;
      }
      .header-cell.name {
        flex: 1.7;
      }

      .header-cell.week,
      .header-cell.type,
      .header-cell.priority,
      .header-cell.responsible,
      .header-cell.chevron {
        flex: 0.5;
      }
      .header-cell.paperclip {
        flex: 0.4;
      }
    }
    .table-content.small {
      height: 55vh;
    }
    .table-content {
      scrollbar-gutter: stable;
      overflow: auto;
      height: 73vh;
      .table-row {
        display: flex;
        border-bottom: 1px solid var(--light-contrast);
        padding: 12px 8px 12px 16px;
        cursor: pointer;
        .table-cell {
          text-align: left;
          font-size: 0.9rem;
          font-weight: 400;
          color: var(--gray-600, #475467);
        }
        .table-cell {
          .Fullført,
          .Bestilt,
          .Startet {
            display: flex;
            justify-content: center;
            border-radius: 1rem;
            height: fit-content;
            padding: 0 0.5rem;
          }
        }

        .table-cell {
          .Bestilt {
            width: fit-content;
            background-color: var(--primary-50, #f0f1f9);
            color: var(--primary-700, #3b409b);
          }
        }
        .table-cell {
          .Fullført {
            width: fit-content;
            background-color: var(--success-50, #ecfdf3);
            color: var(--success-700, #027a48);
          }
        }
        .table-cell {
          .Startet {
            width: fit-content;
            background-color: var(--warning-50, #fffaeb);
            color: var(--warning-700, #b54708);
          }
        }
        .table-cell {
          .Bestilt::before {
            content: "•";
            color: var(--primary-600, #5157bd);
            font-size: 2rem;
            margin-right: 0.5rem;
          }
        }
        .table-cell {
          .Fullført::before {
            content: "•";
            color: var(--success-600, #12b76a);
            font-size: 2rem;
            margin-right: 0.5rem;
          }
        }
        .table-cell {
          .Startet::before {
            content: "•";
            color: var(--warning-600, #f7b924);
            font-size: 2rem;
            margin-right: 0.5rem;
          }
        }
        .table-cell:not(.progress) {
          display: flex;
          align-items: center;
        }
        .table-cell.status {
          flex: 0.7;
        }
        .table-cell.progress {
          flex: 1;
        }
        .table-cell.name {
          flex: 1.7;
        }
        .table-cell.week,
        .table-cell.type,
        .table-cell.priority,
        .table-cell.responsible,
        .table-cell.chevron {
          flex: 0.5;
        }
        .table-cell.paperclip {
          flex: 0.4;
        }
        .table-cell.chevron {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .table-cell.progress {
          display: flex;
          flex-direction: column;
          .progress-bar {
            width: 80%;
          }
          .progress-text {
            display: flex;
            justify-content: center;
            width: 80%;
            p {
              margin: 0.5rem 0 0 0;
            }
          }
        }
      }
      .table-row:hover {
        background-color: #f9fafb;
      }
      .table-row:last-of-type {
        border-bottom: none;
      }
    }
  }
}
