.aui-root .reset-password {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  &__form {
    max-width: 50%;
    &__input.email {
      width: 70%;
      margin-top: 1rem;
    }
    &__header {
      margin-bottom: 0;
    }
    &__desc {
      margin-bottom: 1.2rem;
      text-align: center;
    }
    &__email {
      margin: 0;
    }
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      button {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      input {
        width: 100%;
        margin-bottom: 2rem;
      }
    }
  }
  .previous-page,
  .cancel-reset-password {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
  }
  .previous-page {
    top: 1rem;
    left: 2rem;
  }
  .cancel-reset-password {
    top: 1rem;
    right: 2rem;
  }
}
