.image-uploader {
  .cam-button {
    border-radius: 0.5rem;
    padding: 0.2rem 0.7rem;
    height: 3rem;
    width: 3rem;
    margin-bottom: 0.5rem;
    background-color: white;
    border: 1px solid var(--primary);
    transition: background-color 0.2s ease-in;
  }
  .cam-button:hover {
    background-color: #f8fafc;
    transition: background-color 0.2s ease-in;
  }
  video {
    width: 100%;
  }
  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-height: 3rem;
    .picture-taken {
      display: flex;
      gap: 0.5rem;
      max-height: 3rem;
    }
  }
  .initial-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .aui-icon {
      color: var(--primary);
    }
  }
  .aui-icon {
    color: var(--primary);
  }
}
