.aui-root {
  .attachments {
    width: 100%;
    display: flex;
    flex-direction: column;

    .attachment-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 1rem;
      .attachment-column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
    .attachment-row:hover {
      cursor: pointer;
      background-color: #eef2f6;
    }
  }
}
