.aui-root .page-layout {
  display: grid;
  grid-template-columns: 10vw 1fr;
  height: 100vh;
  .sidebar {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 10vw;
    border-right: 1px solid var(--light-contrast);
    background-color: var(--primary);
    position: sticky;
    top: 0;
    z-index: 1;
    overflow: auto;
    .logo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      margin-bottom: 3rem;
      margin-top: 0.5rem;
      cursor: pointer;
      svg {
        margin-top: 1.5rem;
        width: 80%;
        height: auto;
      }
      .sidebar-title {
        color: var(--dark-contrast);
      }
    }
    .menu {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      .menu-button,
      .logout {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        min-height: 5rem;
        padding: 1rem 0 1rem 0;
        transition: background-color 0.2s ease-in;
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
        }
        svg {
          color: white;
          height: auto;
          max-height: 2.5rem;
          width: 80%;
        }
        p {
          margin: 0.5rem 1rem 0 1rem;
          text-align: center;
          color: white;
        }
        .icon-container {
          .badge {
            width: 1rem;
            height: 1rem;
            background-color: red;
          }
          .aui-icon {
            height: 2.5rem;
            width: 2.5rem;
            svg {
              height: 2.5rem;
              width: 2.5rem;
            }
          }
        }
      }
      .menu-button:hover {
        background-color: #084c2e;
        transition: background-color 0.2s ease-in;
        cursor: pointer;
      }
      .menu-button.selected-btn {
        background-color: #084c2e;
      }
      .logout {
        margin-top: auto;
        margin-bottom: 1rem;
        svg {
          color: white;
        }
        p {
          color: white;
        }
      }
      .logout:hover {
        background-color: #084c2e;
        cursor: pointer;
      }
    }
  }
  .content {
    overflow-y: hidden;
    padding: 0 1rem;
  }
}
