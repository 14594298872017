.aui-root {
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }

  .modal-content {
    background-color: white;
    border-radius: 1rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 30rem;
    max-width: 90%;
  }
  .modal-content.fixed-margin-top {
    position: absolute;
    top: 2%;
  }

  .modal-top-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 2rem;
    margin: 1rem;
    &-header {
      display: flex;
      gap: 1rem;
      align-items: center;
      svg {
        color: var(--success);
      }
    }
  }

  hr {
    margin: 0;
  }

  .modal-body {
    padding: 1rem;
    max-height: 90vh;
    overflow: auto;
  }
}
