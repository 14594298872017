.aui-root {
  .orders {
    .orders-title {
      color: var(--dark-contrast);
      margin: 2vh 0 1vh 0;
    }
    .offline-text {
      position: absolute;
      top: 0.2vh;
      left: 43vw;
    }
  }
}
