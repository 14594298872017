.progress-bar-container {
  width: 100%;
  height: 0.5rem;
  background-color: #f0f0f0;
  border-radius: 10px;
  overflow: hidden;
}

.progress-bar-fill {
  height: 100%;
  background-color: var(--secondary);
}
