.aui-root {
  .flip-buttons {
    position: absolute;
    display: flex;
    max-height: 2.5rem;
    bottom: 3.5rem;
    right: 15%;
    z-index: 2;
    .flip-button {
      flex: 1;
      padding: 10px;
      justify-content: center;
      cursor: pointer;
      background-color: white;
      border: 1px solid var(--primary);
      color: var(--primary);

      &:not(.middle-button) {
        border-radius: 0;
      }

      &.left-button {
        border-radius: 5px 0 0 5px;
        border: 1px solid black;
        color: black;
        background-color: var(--primary-contrast);
        cursor: default;
      }

      &.right-button {
        border-radius: 0 5px 5px 0;
      }

      &.middle-button {
        border-radius: 0;
      }
    }
  }
}
