.details-filter-container {
  p {
    margin: 0;
  }
  .row {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    &:first-child {
      margin-bottom: 0.5rem;
    }
  }
  .dropdown-wrapper {
    margin-right: 1.5rem;
  }

  .reset-filter-btn {
    display: flex;
    align-items: flex-end;
  }
}
