.aui-root .search-container {
  position: relative;

  .search-icon {
    position: absolute;
    left: 0.8rem;
    top: 30%;
  }

  input {
    width: 200px;
    height: 60px;
    padding: 1rem 2.4rem;
    padding-left: 3.5rem;
    border: 1px solid #dbdbdb;
    border-radius: 1.2rem;
    text-align: center;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }
}
