.aui-root {
  .order-info {
    display: flex;
    flex-direction: row;
    &-tasks.small {
      position: relative;
      width: 100%;
    }
    &-tasks.large {
      position: relative;
      width: 42vw;
    }
    &-tasks {
      &-nav-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 2vh;
        .header-gap {
          display: flex;
          flex-direction: row;
          gap: 0.5rem;
          cursor: pointer;
        }
        .expand-container {
          cursor: pointer;
        }
        p {
          color: var(--dark-contrast);
          margin: 0;
        }
      }
      &-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        max-height: 9vh;
        .view-options-container {
          display: flex;
          flex-direction: row;
          gap: 0.5rem;
          .view-option-button {
            border-radius: 0.5rem;
            padding: 0.2rem 0.7rem;
            height: 3rem;
            width: 3rem;
            background-color: white;
            border: 1px solid #d0d5dd;
            transition: background-color 0.2s ease-in;
          }
          .view-option-button:hover {
            background-color: #f8fafc;
            transition: background-color 0.2s ease-in;
          }
        }
      }
      .hr {
        border-top: 1px solid #d0d5dd;
        margin: 0.5rem 0;
      }
      &-filter {
        scrollbar-width: thin;
        display: flex;
        flex-direction: row;
        overflow-y: auto;
        gap: 1rem;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid #d0d5dd;
        box-shadow: 0px 8px 5px -2px rgba(0, 0, 0, 0.1);
        &-btn {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 0.3rem;
          border-radius: 3rem;
          padding: 0.2rem 0.7rem;
          box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
          cursor: pointer;
          p {
            margin: 0;
          }
        }
        &-btn.Bestilt {
          background-color: #eff4ff;
          p {
            color: #155eef;
          }
          svg {
            color: #155eef;
          }
        }
        &-btn.Ikke.fullført {
          background-color: #fffaeb;
          p {
            color: #f79009;
          }
          svg {
            color: #f79009;
          }
        }
        &-btn.Fullført {
          background-color: #ecfdf3;
          p {
            color: #079455;
          }
          svg {
            color: #079455;
          }
        }
      }
      &-boxes {
        display: flex;
        flex-direction: column;
        overflow: auto;
        height: 74vh;
      }
      &-boxes.bottom-padding {
        padding-bottom: 4.5rem;
      }
      &-box {
        display: flex;
        flex-direction: row;
        padding: 0.2rem 0.5rem;
        border-bottom: 1px solid #d9d9d9;
        border-top: 1px solid #d9d9d9;
        border-left: 1px solid white;
        border-right: 1px solid white;
        cursor: pointer;
        &-left {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          flex: 1.5;
          min-height: 5.5rem;
          p {
            margin-top: 0.5rem;
            margin-bottom: 0;
            text-align: center;
          }
          .aui-icon {
            height: 2.5rem;
            width: 2.5rem;
            svg {
              height: 2.5rem;
              width: 2.5rem;
            }
          }
        }
        &-middle {
          display: flex;
          flex-direction: column;
          flex: 6;
          align-items: flex-start;
          justify-content: space-evenly;
          p {
            margin: 0.2rem;
          }
        }
        &-right {
          display: flex;
          flex: 1;
          align-items: center;
          justify-content: space-evenly;
        }
      }
      &-box.selected {
        background-color: #f9fafb;
        border: 1px solid #d0d5dd;
      }
    }
    &-map {
      width: 45%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
    }
    &-map.fullscreen {
      width: 90vw;
    }
  }
  .order-info.internal-messages {
    .order-info-tasks-boxes {
      height: 82vh;
    }
  }
  .collapse-container {
    display: flex;
    cursor: pointer;
    position: absolute;
    background-color: var(--primary);
    top: 1vh;
    left: 11vw;
    border-radius: 0.5rem;
    padding: 0.5rem;
    span {
      color: white;
      width: 100%;
      height: 100%;
    }
  }
  .connection-text {
    position: absolute;
    top: 0.05vh;
    left: 20vw;
    p {
      color: var(--dark-contrast);
    }
  }
  .offline-text {
    color: #f04438;
    margin: 0;
  }
  .online-text {
    color: #079455;
    margin-top: 0;
  }
  .expand-icon svg {
    width: 2rem;
    height: 2rem;
  }
  .btn.driving-btn {
    position: absolute;
    transform: translate(-50%, -50%);
    bottom: -2vh;
    left: 50%;
    border: 1px solid #344054;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .driving-btn.stop-driving {
    background-color: #dc6803;
  }
  .driving-btn.stop-driving:hover {
    background-color: #dc6803;
  }
}
