.aui-root {
  .attachment-modal {
    &-footer {
      display: flex;
      justify-content: end;
      margin-top: 2rem;
      .btn {
        width: 40%;
        display: flex;
        justify-content: center;
      }
    }
  }
}
