.task-filter-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.3rem;
  height: 2.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  border: 1px solid white;

  &.selected {
    border: 1px solid #285c3e;
    background-color: #f8fafc;
  }
  background-color: transparent;

  .button-text {
    color: #697586;
    transition: color 0.3s;
    font-weight: 600;
  }

  .number-container {
    background-color: #eef2f6;
    border-radius: 1rem;
    padding: 0.2rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.task-filter-button.selected {
  .button-text {
    color: #364152;
  }
}

.task-filter-button.smaller {
  height: 2rem;
  .button-text {
    font-size: 0.8rem;
  }
  .number-container {
    padding: 0.1rem 0.3rem;
    font-size: 0.8rem;
  }
}
